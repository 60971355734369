import {Destination} from '@wix/wixstores-graphql-schema-node';
import {getCountryShortKey} from './localeDataset.util';
import {CountryCode} from '@wix/form-fields';

export const getEnabledDeliveryCountries = (destinations?: Destination[]): CountryCode[] => {
  let isAsteriskFound = false;
  const enabledDeliveryCountries = (destinations ?? [])
    .map((destination) =>
      destination.locations.map((location) => {
        if (location.code === '*') {
          isAsteriskFound = true;
          return '*';
        } else {
          return getCountryShortKey(location.code);
        }
      })
    )
    .flat() as CountryCode[];
  return isAsteriskFound ? [] : enabledDeliveryCountries;
};
